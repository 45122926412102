import React, { useCallback, useMemo } from 'react';

import { Text, IconButton } from 'wix-ui-tpa';

import { useSettings } from '@wix/tpa-settings/react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { useAppDispatch } from '@/store';
import { minimizeWidget } from '@/features/layout/layoutThunks';
import Minus from '@/assets/icons/customMinimizeButton.svg';

import { iconsDict } from 'AiAssistantWidget/Settings/components';
import settingsParams from 'AiAssistantWidget/settingsParams';

import { AIAvatarIcon } from '../svg';

import { st, classes } from './Header.st.css';

export const Header = () => {
  const { t } = useTranslation();
  const settings = useSettings();
  const dispatch = useAppDispatch();

  const useAvatar = useMemo(
    () => settings.get(settingsParams.displayChatbotAvatar),
    [settings],
  );

  const name = useMemo(
    () => settings.get(settingsParams.textHeader),
    [settings],
  );

  const icon = useMemo(() => {
    const iconKey = settings.get(settingsParams.minimizedButtonIcon);

    return iconKey ? iconsDict[iconKey] : <AIAvatarIcon />;
  }, [settings]);

  const onClickMinimize = useCallback(() => {
    dispatch(minimizeWidget());
  }, [dispatch]);

  return (
    <div className={st(classes.root)}>
      <div className={st(classes.left)}>
        {useAvatar && <div className={st(classes.avatar)}>{icon}</div>}

        <Text className={st(classes.name)}>{name}</Text>
      </div>
      <div className={st(classes.right)}>
        <IconButton
          contentClassName={st(classes.minimizeButton)}
          icon={<Minus />}
          onClick={onClickMinimize}
          className={st(classes.minimizeButton)}
          aria-label={t('app.widget.ariallabel.minimize')}
        />
      </div>
    </div>
  );
};
