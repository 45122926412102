import React from 'react';

import { Provider as ReduxProvider } from 'react-redux';

import { ISiteApis, WidgetProps } from '@wix/yoshi-flow-editor';

import { useGetStore } from '@/store';

import { OnHandlerEvent, OnHandlerReset } from 'AiAssistantWidget/types';

import {
  EditorSettingsEventsProvider,
  RootPropsProvider,
  SiteNavigation,
  SiteNavigationProvider,
} from '../../contexts';
import { ChatWrapper } from '../ChatWrapper';
import { MobileActionBarButton } from '../MobileActionBarButton';

export type ControllerProps = {
  onHandlerEvent: OnHandlerEvent;
  onHandlerReset: OnHandlerReset;
  navigation: SiteNavigation;
  instanceToken: string;
  page: ISiteApis['currentPage'];
};

export const ControllerValidation: React.FC<WidgetProps<ControllerProps>> = ({
  onHandlerEvent,
  onHandlerReset,
  navigation,
  instanceToken,
  page,
  host,
}) => {
  const store = useGetStore(instanceToken!);
  return (
    <div>
      <ReduxProvider store={store}>
        <RootPropsProvider p={{ page, instanceToken, host }}>
          <EditorSettingsEventsProvider
            onHandlerEvent={onHandlerEvent}
            onHandlerReset={onHandlerReset}
          >
            <SiteNavigationProvider navigation={navigation}>
              <ChatWrapper />
              <MobileActionBarButton host={host} />
            </SiteNavigationProvider>
          </EditorSettingsEventsProvider>
        </RootPropsProvider>
      </ReduxProvider>
    </div>
  );
};
