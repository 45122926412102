import { useCallback } from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { minimizeWidget } from '@/features/layout/layoutThunks';
import { useAppDispatch } from '@/store';

import { useSiteNavigation } from 'AiAssistantWidget/Widget/contexts';

export const useLinkHandler = (link: string = '') => {
  const dispatch = useAppDispatch();
  const { isMobile } = useEnvironment();
  const { navigateToUrl, baseUrl } = useSiteNavigation();

  const isOwn = link.startsWith(baseUrl) || link.startsWith('/');

  const handleLinkClick = useCallback(() => {
    if (isMobile) {
      dispatch(minimizeWidget());
    }
    navigateToUrl(link);
  }, [dispatch, isMobile, link, navigateToUrl]);
  return {
    isOwn,
    handleLinkClick,
  };
};
