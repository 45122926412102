import { Message } from '../../../../../features';

import { GenericPayload, SupportedPayloadType } from './types';
import { supportedPayloadTypes } from './config';

export const NOT_SUPPORTED_PAYLOAD = '[notSupportedPayload]';

// convert list of random type payloads into a dict, where the key is a type and value is a list of payloads
// it also will have all not supported payloads available with NOT_SUPPORTED_PAYLOAD key
export type PayloadsMapped = {
  [NOT_SUPPORTED_PAYLOAD]: GenericPayload[];
} & Partial<Record<SupportedPayloadType, GenericPayload[]>>;

export function getPayloadsMapped(message: Message): PayloadsMapped {
  if (!message.structPayloads?.length) {
    return { [NOT_SUPPORTED_PAYLOAD]: [] };
  }
  return (message.structPayloads as GenericPayload[]).reduce(
    (acc, payload) => {
      const type = payload.type as SupportedPayloadType;
      if (supportedPayloadTypes.has(type)) {
        acc[type] = acc[type] || [];
        acc[type]!.push(payload);
      } else {
        console.warn(`Unsupported payload type ${type}`, payload);
        acc[NOT_SUPPORTED_PAYLOAD].push(payload);
      }
      return acc;
    },
    { [NOT_SUPPORTED_PAYLOAD]: [] } as PayloadsMapped,
  );
}
